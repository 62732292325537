@font-face {
  font-family: "LEMONMILK_BOLD";
  src: url("./assets/fonts/lemon_milk/LEMONMILK-Bold.otf");
}
@font-face {
  font-family: "LEMONMILK_MEDIUM";
  src: url("./assets/fonts/lemon_milk/LEMONMILK-Medium.otf");
}
@font-face {
  font-family: "LEMONMILK_REGULAR";
  src: url("./assets/fonts/lemon_milk/LEMONMILK-Regular.otf");
}
@font-face {
  font-family: "LEMONMILK_LIGHT";
  src: url("./assets/fonts/lemon_milk/LEMONMILK-Light.otf");
}
@font-face {
  font-family: "AVENIR";
  src: url("./assets/fonts/AvenirLight/AvenirLight.ttf");
}

body {
  margin: 0;
  font-family: "AVENIR";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}