.SectionFour{
    max-width: var(--max-width);
    margin: auto;
}
.Container{
    color:var(--white);
    width:75%;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.Container.SecondContainer{
    flex-direction: row-reverse;
}
.TextContainer{
    flex-basis: 60%;
    text-align: left;
}
.ImageContainer{
    flex-basis: 40%;
}
.ImageContainer img{
    height:auto;
    width: 100%;
}

.SecondContainer .ImageContainer img{
    width: 80%;
    border: 2px solid var(--white);
}
.TextOne p:nth-child(1){
    font-size: 40px;
    font-family: 'LEMONMILK_REGULAR';
}
.TextOne p{
    margin-bottom: 15px;
}
.TextTwo{
    margin-top: 15px;
}
.TextOne ul{
    margin: 0;
    list-style-type: none;
    color: var(--gray);
}
.TextOne p.HardwareSpecs{
    font-weight: bold;
    margin-bottom: 0;
    font-size: 18px;
}
.One p{
    font-weight: 600;
    font-size: 18px;
}
.ListItem{
    margin-bottom: 15px;
}
.ListItem span{
    font-family: 'LEMONMILK_REGULAR';
    font-size: 18px;
    
}
.EditionsSection{
    display: flex;
    justify-content: space-between;
}
.EditionsSection div:nth-child(1){
    animation: FadeIn 1s forwards;
    opacity: 0;
}
.EditionsSection div:nth-child(2){
    animation: FadeIn 1s forwards;
    -webkit-animation-delay: 1s;
    opacity: 0;
}
.EditionsSection div:nth-child(3){
    animation: FadeIn 1s forwards;
    -webkit-animation-delay: 2s;
    opacity: 0;
}

@keyframes FadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 100;
    }
}

@media(max-width:1200px){
}
@media(max-width:800px){
}
@media(max-width:768px){
    .TextOne p:nth-child(1){
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .TextContainer{
        width: 100%;
    }
    .ImageContainer{
        margin-top: 30px;
        text-align: center;
    }
    .Container{
        width:75%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    }
    .Container.SecondContainer{
        flex-direction: column;
    }
}
@media(max-width:600px){
    .Container{
    }
}
@media(max-width:550px){
    .Container{
    }
}
@media(max-width: 500px){
    .TextContainer{
        width: 95%;
    }
    .Container{
        width: 95%;
    }
}
@media(max-width:400px){
}