*{
    margin: 0;
    padding: 0;
}
.SectionEight{
    max-width: var(--max-width);
    margin: auto;
}
.Container{
    color: var(--white);
    width:75%;
    margin: 40px auto 0px auto;   
}
img{
    color:white;
}
.CardContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 16px;
}
.Card{
   text-align: center;
   width:30%;    
}
/* .Card img{
    border: 2px solid var(--white);
} */

.Card p{
    margin-top: 5px;
}
.TextContainer{
    margin:0px 0px 40px 0px;
   /* border: 2px solid green;*/
}
.TextContainer p:nth-child(1){
    font-size: 40px;
    font-family: 'LEMONMILK_REGULAR';
    margin-bottom: 10px;
}
.TextContainer p:nth-child(2){
    font-size: 18px;
}

.Container2{
    text-align: center;
    box-sizing: border-box;
}
.Hide{
    display: none;
}
@media(max-width:1100px){
    .Card{
        margin-bottom: 20px;
    }
}
@media(max-width:800px){
    .Card{
        margin-bottom: 10px;
    }
    .Container{
        color: var(--white);
        width:90%;
        margin: 0px auto 0px auto;       
    }
}
@media(max-width:768px){
    .TextContainer p:nth-child(1){
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .CardContainer{
        display: block;
    }
    .Card{
        width:50%;
        margin: auto;
    }

}
@media(max-width:500px){
    .Container{
    }
    
    .Card{
        margin-bottom: 10px;
    }
}
@media(max-width:400px){
    .Card{
        margin-bottom: 10px;
    }
}
@media(max-width:300px){
    
    .Card{
        margin-bottom: 10px;
    }
}