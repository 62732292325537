.SectionSix{
    max-width: var(--max-width);
    margin: auto;
}
.Container{
    color: var(--white);
   /* border:2px solid red;*/
    width:75%;
    margin: 80px auto 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.TextContainer{
    /*border:2px solid green;*/
    width:40%;
    padding-top: 20px;
}
.TextContainer p:nth-child(1){
    font-size: 40px;
    font-family: 'LEMONMILK_REGULAR';
    margin-bottom: 30px;
}
.TextContainer p:nth-child(2){
    font-size: 18px;
}

.BlankCanvas{
    height:300px;
    width: 35%;
}
model-viewer {
  width: 100%;
  height: 300px;
  margin: auto;
}
@media(max-width:1028px){
    .Container{
        color: var(--white);
       /* border:2px solid red;*/
        width:80%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
       
    }
}
@media(max-width:800px){
    .Container{
        color: var(--white);
       /* border:2px solid red;*/
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .TextContainer{
        /*border:2px solid green;*/
        width:50%;
        padding-top: 20px;
        margin:0 auto 40px auto;
    }
    .BlankCanvas{
       margin: 0 auto;
    }
}
@media(max-width:768px){
    .TextContainer p:nth-child(1){
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 30px;
    }
    .Container{
        margin: 30px auto 0px auto;
    }
}
@media(max-width:500px){
    .Container{
        color: var(--white);
       /* border:2px solid red;*/
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .TextContainer{
        /*border:2px solid green;*/
        width:90%;
        margin:0 auto 40px auto;
    }
    .BlankCanvas{
        width:90%;
    }
}
@media(max-width:400px){
    .Container{
        color: var(--white);
       /* border:2px solid red;*/
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}
@media(max-width:300px){
    .Container{
        color: var(--white);
       /* border:2px solid red;*/
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}