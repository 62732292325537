.SectionFive{
}
.Container{
    background: var(--white);
    text-align: center;
    padding: 30px 0;
}
.Container div{
    font-family: LEMONMILK_REGULAR;
}
.Container div:nth-child(1){
    font-size: 16px;
    font-weight: bold;
}
/*.Container div:nth-child(2){
    font-size: 12px;
}
.Container div:nth-child(3){
    font-size: 12px;
    margin-top: 20px;
}
.Container a{
    color: var(--black);
}*/
.Logos img{
    width: 250px;
}
.Logos div:nth-child(1) img{
}
.Logos div:nth-child(2) img{
}
.Logos div:nth-child(3) img{
    height: 60px;
    width: auto;
}
.Logos div:nth-child(4) img{
    height: 70px;
    width: auto;
}
.Logos div:nth-child(5) img{
    height: 100px;
    width: auto;
}
.Logos div:nth-child(6) img{
    height: 70px;
    width: auto;
}

.Logos{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 800px;
    margin: auto;
    align-items: center;
}
.Logos > div{
    margin: 5px 0;
}

@media (max-width: 900px){
    .Logos{
        width: 100%;
    }
}
@media (max-width: 768px){
    .Logos img{
        width: auto;
        height: 80px;
    }
    .Logos div:nth-child(4) img{
        height: 50px;
    }
    .Logos div:nth-child(5) img{
        height: 90px;
    }
    .Logos div:nth-child(6) img{
        height: 50px;
    }
}
@media (max-width: 350px){
    .Logos div:nth-child(5) img{
        height: 70px;
    }
}