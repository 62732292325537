.account-page {
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    min-height: 100vh; /* Full viewport height */
    background-color: #f7fafc; /* Light gray background */
}

.account-container,
.success-message-container {
    background-color: #ffffff; /* White background */
    padding: 32px; /* Equivalent to p-8 */
    border-radius: 8px; /* Equivalent to rounded-lg */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to shadow-lg */
    max-width: 400px; /* Equivalent to max-w-md */
    width: 100%;
    margin: 0 auto; /* Center the message */
    text-align: center;
}

.account-title {
    font-size: 24px; /* Equivalent to text-3xl */
    font-weight: bold;
    text-align: center;
    margin-bottom: 32px; /* Equivalent to mb-8 */
}

/* Merged styles from SignupForm.css */
.signup-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.wallet-info {
    margin-bottom: 24px;
}

.wallet-owner {
    font-weight: bold;
}

.wallet-address {
    font-size: 14px;
    color: #4a5568; /* Dark gray */
}

.form-instructions {
    margin-bottom: 16px;
}

.input-group {
    margin-bottom: 16px;
}

.input-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #4a5568; /* Dark gray */
}

.input-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #cbd5e0; /* Light gray */
    border-radius: 4px;
    transition: border-color 0.2s;
}

.input-field:focus {
    border-color: #000000; /* Black */
    outline: none;
}

.button {
    width: 100%;
    padding: 12px;
    font-weight: bold;
    text-align: center;
    border-radius: 9999px;
    transition: background-color 0.2s, color 0.2s;
}

.button-primary {
    background-color: #000000; /* Black */
    color: #ffffff; /* White */
}

.button-primary:hover {
    background-color: #ffffff; /* White */
    color: #000000; /* Black */
    border: 2px solid #000000; /* Black */
}

.button-secondary {
    background-color: #ffffff; /* White */
    color: #000000; /* Black */
    border: 2px solid #000000; /* Black */
}

.button-secondary:hover {
    background-color: #000000; /* Black */
    color: #ffffff; /* White */
}

.error-message {
    color: #e53e3e; /* Red */
    font-size: 14px;
}

.success-message {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Full viewport height */
    background-color: #f7fafc; /* Light gray background */
}

.success-message h2 {
    font-size: 24px; /* Equivalent to text-3xl */
    font-weight: bold;
    margin-bottom: 16px;
}

.success-message p {
    margin-top: 16px;
}

.success-message a {
    color: #3182ce; /* Blue */
    text-decoration: underline;
}