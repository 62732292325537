.SectionSeven{
	padding: 50px 0;
	background: var(--black);
    max-width: var(--max-width);
    margin: auto;
}
.Container{
	width: 75%;
	margin: auto;
	text-align: left;
	color: var(--white);
}

.Roadmap .Title{
	font-size: 40px;
	font-family: 'LEMONMILK_REGULAR';
	padding-bottom: 10px;
	border-bottom: 7px solid var(--white);
}

.BoxContainer{
	border: 2px solid var(--white);
	padding: 50px 20px;
	margin-top: 15px;
	font-size: 18px;
}

.BoxContainer .Up, .BoxContainer .Down{
	display: flex;
}
.BoxContainer .Up div, .BoxContainer .Down div{
	flex-basis: 30%;
	border-left: 3.5px solid var(--white);
	padding: 10px 5px;
	padding-left: 10px;
	box-sizing: border-box;
}
.BoxContainer .Up{
	justify-content: flex-end;
	border-bottom: 3.5px solid var(--white);
}
.BoxContainer .Down{
	justify-content: flex-start;
	border-top: 3.5px solid var(--white);
}


@media (max-width: 950px){
	.Container{
		width: 90%;
	}
}

@media (max-width: 768px){
	.BoxContainer{
		font-size: 14px;
	}
	.Roadmap .Title{
		font-size: 35px;
		font-weight: bold;
	}


}

@media (max-width: 500px){
	.BoxContainer{
		font-size: 12px;
	}
	.BoxContainer{
		padding: 30px 5px;
	}
}

@media (max-width: 400px){
	.BoxContainer{
		font-size: 11px;
	}	
}