.SectionOne{
  background: var(--black);
}
.Container{
  height: 60vh;
  overflow: hidden;
  border-bottom: 2px solid var(--white);
}
section.ImageBG {
  background-image: url('../../../assets/images/compressed_webp/EthBanner.webp'), url('../../../assets/images/compressed/EthBanner.png');
  background-position: center -60px;
  background-attachment: fixed;
  transform: translateZ(-1px) scale(1.0);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  height: 100%;
}
.PodzShowcaseContainer{
    max-width: var(--max-width);
    margin: auto;
}
.PodzShowcase{
  color: var(--white);
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 75%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ShowcaseImage img{
  width: 350px;
}
.ShowcaseImage{
  flex-basis: 50%;
}
.MainText{
  flex-basis: 50%;
}
.MainText .Title{
  font-weight: bold;
  margin: 10px 0;
}
.MainText .Title div:nth-child(1){
  font-size: 18px;
  font-family: 'LEMONMILK_REGULAR';
}
.MainText .Title div:nth-child(2){
  font-size: 40px;
  font-family: 'LEMONMILK_REGULAR';
}

.MainText .SubText{
  font-size: 16px;
}
@media (max-width: 1370px){
  section.ImageBG {
    background-attachment: scroll;
    background-position: center 0px;
  }
}
@media (max-width: 950px){
  .PodzShowcase{
    width: 95%;
  } 
}
@media (max-width: 768px){
  .MainText .Title div:nth-child(1){
    font-size: 18px;
  }
  .MainText .Title div:nth-child(2){
    font-size: 35px;
  }
  .MainText .SubText{
    font-size: 12px;
  }
  .ShowcaseImage img{
    width: auto;
    height: 200px;
  }
  section.ImageBG {
    background-image: url('../../../assets/images/compressed_webp/Ethereum.webp'), url('../../../assets/images/compressed/Ethereum.png');
  }
}
@media (max-width: 550px){
  .PodzShowcase{
    display: block;
    text-align: center;
  } 
}