.SectionTwo{
  background: var(--black);
}
.Container{
  max-width: var(--max-width);
  margin: auto;
}

.Container .Text{
  width: 75%;
  margin: auto;
}
.PodzWar{
  display: flex;
  color: var(--white);
  justify-content: space-around;
  align-items: center;
  padding: 50px 20px;
  border-right: 3.5px solid var(--white);
}
.PodzWar .WarImg{
  flex-basis: 40%;
}
.PodzWar .WarImg video{
  height: 100%;
  width: 100%;
  max-height: 280px;
}
.VideoTeaser{
  height: 280px;
  width: 100%;
}
.PodzWar .WarImg img{
  width: 100%;
}
.PodzWar .WarText{
  text-align: left;
  margin: 0 50px;
  flex-basis: 60%;
}
.PodzWar .WarText div:nth-child(1){
  font-size: 40px;
  font-family: 'LEMONMILK_REGULAR';

}
.PodzWar .WarText div:nth-child(2){
  font-size: 16px;
  margin-top: 10px;
}
.PodzWar .WarText div:nth-child(3){
  font-size: 16px;
  margin-top: 25px;
}
.WarText p{
  margin: 15px 0;
}
.WarText p span{
  font-weight: bold;
}
.Image{
  height: 70vh;
  width: 100%;
  overflow: hidden;
  border-top: 7px solid var(--white);
  border-bottom: 7px solid var(--white);
}

.Image .ImageContainer img{
  width: 100%;
  border-top: 8px solid var(--white);
  border-bottom: 8px solid var(--white);
  filter: brightness(0.7);
}

.BuyPodOpenSea{
  background: var(--white);
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 25px;
  border-radius: 7px;
  border-bottom-right-radius: unset;
}

.BuyPodOpenSea div:nth-child(1){
  font-size: 40px;
  font-weight: 700;
  flex-basis: 30%;
  padding-left: 20px;
  box-sizing: border-box;
  font-family: 'LEMONMILK_REGULAR';
}
.BuyPodOpenSea div:nth-child(2){
  text-align: left;
  font-size: 16px;
  font-weight: 100;
  flex-basis: 40%;
  padding: 0 10px;
  box-sizing: border-box;
}
.BuyPodOpenSea div:nth-child(3){
  flex-basis: 25%;
}
.BuyPodOpenSea div:nth-child(3) button{
  outline: none;
  background: transparent;
  border: 2px solid var(--black);
  border-radius: 10px;
  padding: 15px;
  font-size: 20px;
  font-family: 'LEMONMILK_REGULAR';
  cursor: pointer;
  transition: 0.3s;
  width: -webkit-fill-available;
}

.BuyPodOpenSea div:nth-child(3) button:hover{
  background: var(--black);
  color: var(--white);
}

.ImageContainer{
  background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('../../../assets/images/battlepodz_webp/Banner2.webp'), url('../../../assets/images/BattlePodz/Banner2.png');
  background-position: center center;
  background-attachment: fixed;
  transform: translateZ(-1px) scale(1.1);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  height: 100%;
}

.BattlePodzVideo{
  display: flex;
  justify-content: center;
  height: 100%;
}
.BattlePodzVideo iframe{
  display: flex;
  align-self: center;
  height: 70%;
  width: 50%;
}
.Editions{
  display: flex;
  flex-wrap: wrap;
}
.Editions p{
  margin: 5px 15px 0 0;
}

.PodzDesc div img{
  height: 100px;
  display: block;
}
.PodzDesc{
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  justify-content: space-around;
}
.PodzWar .WarText .PodzDesc div{
  margin: 0;
  font-size: 16px;
  text-align: center;
  font-family: AVENIR;
}

@media (max-width: 1370px){
  .ImageContainer{
    background-attachment: scroll;
  }
  .BattlePodzVideo iframe{
    width: 70%;
  }
}

@media (max-width: 950px){
  .Container .Text{
    width: 95%;
  }
}

@media (max-width: 768px){
  .BuyPodOpenSea{
    display: block;
    padding: 5px 0;
    text-align: center;
  }
  .BuyPodOpenSea div:nth-child(1){
    padding-left: 0px;
  }
  .BuyPodOpenSea div{
    margin: 15px 0;
  }
  .PodzWar{
    display: block;
    padding: 50px 0;
  }
  .PodzWar .WarText{
    margin: 20px 10px;
  }
  .PodzWar .WarText div:nth-child(1){
    font-size: 35px;
  }
  .PodzWar .WarText div:nth-child(2){
    font-size: 14px;
  }
  .PodzWar .WarText div:nth-child(3){
    font-size: 14px;
  }
  .PodzWar .WarText .PodzDesc div{
    font-size: 16px;
    font-family: AVENIR;
  }
  .PodzWar .WarImg img{
    width: 100%;
  }
  .ImageContainer{
    background-size: cover;
    background-position: center;
  }
  .BattlePodzVideo iframe{
    width: 80%;
  }
  .BuyPodOpenSea div:nth-child(3) button{
    width: 250px;
  }
  .Editions{
    flex-wrap: unset;
  }
}