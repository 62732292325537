.SectionOne{
  max-width: var(--max-width);
  margin: auto;
}
.Container{
  color:var(--white);
  width: 60%;
  margin: 50px auto 0 auto;
  padding-bottom: 50px;
  border-right: 5px solid var(--white);
  border-top-right-radius: 12px;
}
.SecondContainer{
  color:var(--white);
  width: 60%;
  margin: 50px auto 50px auto;
  flex-direction: column-reverse;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.TitleText{
  margin-top: 150px;
  color: var(--white);
  font-size: 40px;
  font-family: 'LEMONMILK_REGULAR';
  text-align: center;
}
.BuyPodOpenSea{
  background: var(--white);
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  border-radius: 7px;
  border-bottom-right-radius: unset;
}

.BuyPodOpenSea > div:nth-child(1) > div:nth-child(1){
  font-size: 40px;
  font-weight: 700;
  flex-basis: 30%;
  box-sizing: border-box;
  font-family: 'LEMONMILK_REGULAR';
}
.BuyPodOpenSea > div:nth-child(1) > div:nth-child(2){
  text-align: left;
  font-size: 16px;
  font-weight: 100;
  flex-basis: 40%;
  box-sizing: border-box;
}
.BuyPodOpenSea > div{
  flex-basis: 50%;
}
.BuyPodOpenSea > div:nth-child(2) button{
  outline: none;
  background: transparent;
  border: 2px solid var(--black);
  border-radius: 10px;
  padding: 15px;
  font-size: 20px;
  font-family: 'LEMONMILK_REGULAR';
  cursor: pointer;
  transition: 0.3s;
  width: -webkit-fill-available;
  margin-bottom: 10px;
  margin-top: 10px;
}

.BuyPodOpenSea > div:nth-child(2) button:hover{
  background: var(--black);
  color: var(--white);
}
.TextContainer{
    flex-basis: 60%;
    text-align: left;
}
.ImageContainer{
    flex-basis: 40%;
    text-align: center;
}
.ImageContainer img{
    height: 450px;
    width: auto;
    border: 2px solid var(--white);
}

.ErrorText{
    color: red;
}
.ErrorText a{
    color: red;
    font-weight: bold;
}

.TextOne p{
    margin-bottom: 15px;
    font-size: 16px;
}
.TextOne p img{
    filter: invert(1);
    height: 15px;
}
.TextTwo{
    margin-top: 15px;
}
.TextOne ul{
    margin-left: 20px;
    list-style-type: circle;
    font-size: 14px;
}
.HideReq{
    height: 0;
    overflow: hidden;
}
.RotateImg{
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
}
.TextOne p.HardwareSpecs{
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 30px;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
}
.One p{
    font-weight: 600;
    font-size: 18px;
}
.ListItem{
    margin-bottom: 15px;
}
.ListItem span{
    font-family: 'LEMONMILK_REGULAR';
    font-size: 18px;
    
}
.EditionsSection{
    display: flex;
    justify-content: space-between;
}
.EditionsSection div:nth-child(1){
    animation: FadeIn 1s forwards;
    opacity: 0;
}
.EditionsSection div:nth-child(2){
    animation: FadeIn 1s forwards;
    -webkit-animation-delay: 1s;
    opacity: 0;
}
.EditionsSection div:nth-child(3){
    animation: FadeIn 1s forwards;
    -webkit-animation-delay: 2s;
    opacity: 0;
}

.VideoBackground{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    filter: brightness(0.3);
    -webkit-filter: brightness(0.3);
    -moz-filter: brightness(0.3);
}
.VideoBackground video{
    width: 100%;
}

@keyframes FadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 100;
    }
}

@media(max-width: 1100px){
    .SecondContainer{
        width: 90%;
    }
    .Container{
        width: 90%;
    }
}
@media(max-width:768px){
    .ImageContainer{
        margin-top: 30px;
        text-align: center;
    }
    .Container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    }
    .Container.SecondContainer{
        flex-direction: column;
    }
    .BuyPodOpenSea{
      display: block;
      padding: 5px 0;
      text-align: center;
    }
    .BuyPodOpenSea  > div:nth-child(1) > div:nth-child(1){
      padding-left: 0px;
    }
    .BuyPodOpenSea > div:nth-child(1) > div:nth-child(2){
        width: 98%;
        margin: auto;
        text-align: center;
    }
    .BuyPodOpenSea div{
      margin: 15px 0;
    }
    .BuyPodOpenSea > div:nth-child(2) button{
      width: 250px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
}
@media(max-width: 500px){
    .Container{
        width: 95%;
    }
    .ImageContainer img{
        width: 90%;
        height: auto;
    }
}
