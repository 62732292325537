.SectionFour{
    max-width: var(--max-width);
    margin: auto;
}
.Container{
    color: var(--white);
   /* border:2px solid red;*/
    width:390px;
    margin: 0px auto 80px auto;
    padding-top: 50px;
}
.ParaContainer p{
    text-align: center;
    font-size: 40px;
    font-family: 'LEMONMILK_REGULAR';
    margin-bottom: 5px;
    font-weight: bold;
}
.Ruler{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Ruler hr{
    background-color: var(--white);
}
.Ruler hr:nth-child(1){
    width:40px;
    height:7px;
    margin-right: 10px;
}
.Ruler hr:nth-child(2){
    width:350px;
    height:7px;
}
.ContentRendering{
    color:var(--white);
    /*border: 2px solid orange;*/
    margin-bottom: 25px;
}
.ObjectContainer{
    margin-top: 25px;
   /* border: 2px solid blue;*/
    width:100%;
}
.ObjectContainer p:nth-child(1){
    font-family: 'LEMONMILK_REGULAR';
    font-size: 18px;
}
.ObjectContainer p:nth-child(2){
    font-size: 14px;
}

@media(max-width:1100px){
    .Container{
        width:400px;
        margin: 0px auto 80px auto;
      }
    .ObjectContainer{
       width: 100%;
        /*border: 2px solid blue;*/
        }  
}
@media(max-width:800px){
    .Container{
        width:390px;
        margin: 0px auto 80px auto;
      
    }
    .ObjectContainer{
        width:100%;
        /*border: 2px solid blue;*/
       }
}
@media(max-width:768px){
    .ParaContainer p{
        text-align: center;
        font-size: 35px;
        margin-bottom: 5px;
        font-weight: bold;
    }
    .Container{
        width:75%;
    }
}

@media(max-width: 500px){
    .Container{
        width:90%;
    }

}