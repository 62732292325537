.SectionFive{
    max-width: var(--max-width);
    margin: auto;
    padding-bottom: 70px;
}
.Container{
    width:75%;
    margin: 20px auto;
}
.Container img{
    border: 2px solid var(--white);
    width:100%;
    margin-top: 50px;
}
@media(max-width: 768px){
    .SectionFive{
        padding-bottom: 30px;
    }   
    .Container{
        margin-top: -18px;
    }
    .Container img{
        margin-top: 0px;
    }
}
@media(max-width:600px){
    .Container{
        width:80%;
    }
}
@media(max-width:500px){
    .Container{
        width:90%;
    }
}