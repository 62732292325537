.SectionThree{
    margin: auto;
    border-top: 5px solid var(--white);
    border-bottom: 5px solid var(--white);
    background: var(--black);
}
.Container{
    color: var(--white);
    margin: 0px auto 0px auto;
    padding-top: 50px;
}
.ParaContainer p{
    text-align: center;
    font-size: 40px;
    font-family: 'LEMONMILK_REGULAR';
    margin-bottom: 5px;
    font-weight: bold;
}
.Ruler{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Ruler hr{
    background-color: var(--white);
}
.Ruler hr:nth-child(1){
    width:40px;
    height:7px;
    margin-right: 10px;
}
.Ruler hr:nth-child(2){
    width:350px;
    height:7px;
}
.ContentRendering{
    color:var(--white);
    margin-bottom: 25px;
    width: 390px;
    margin: auto;
}
.ObjectContainer{
    margin-top: 25px;
    margin-bottom: 25px;
    width:100%;
}
.ObjectContainer p:nth-child(1){
    font-family: 'LEMONMILK_REGULAR';
    font-size: 18px;
}
.ObjectContainer p span{
    font-family: 'AVENIR';
    font-family: bold;
}
.ObjectContainer p:nth-child(2){
    font-size: 14px;
}
.PodzCoin{
    text-align: center;
    margin: 20px 0;
}
.PodzCoin img{
    height: 100px;
}
@media(max-width:1100px){
    .ObjectContainer{
       width: 100%;
    }  
}
@media(max-width:768px){
    .ParaContainer p{
        text-align: center;
        font-size: 35px;
        margin-bottom: 5px;
        font-weight: bold;
    }
}

@media(max-width: 500px){
    .Container{
        width:95%;
    }
    .ContentRendering{
        width: 100%;
    }
}