.SectionTwo {
    font-family: 'LEMONMILK_REGULAR';
    padding: 2.5% 0;
    background-color: var(--black);
    color: var(--white);
    content: center;
    border-top: 8px solid var(--white);
    border-bottom: 8px solid var(--white);
    display: flex; /* Added for centering */
    justify-content: center; /* Added for centering */
    align-items: center; /* Added for centering */
}

.SectionTwo frame p {
    font-family: 'Proxima';
    font-size: 18px;
    padding-left: 40px;
    margin: auto;
}

.SectionTwo iframe {
    width: 60%;
    height: 500px;
    border-radius: 15px; /* Moved from .Iframe */
    overflow: hidden; /* Moved from .Iframe */
}

.SectionTwo frame button {
    font-size: 9px;
    font-family: 'Proxima';
}

@media (max-width: 1100px) {
    .SectionTwo iframe {
        width: 90%;
        height: 500px;
    }
}