.MintForm input[type="radio"]:checked,
.MintForm input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.MintForm input[type="radio"]:checked + label,
.MintForm input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: var(--white);
}
.MintForm input[type="radio"]:checked + label:before,
.MintForm input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.MintForm input[type="radio"]:checked + label:after,
.MintForm input[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #f87da9;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.MintForm input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.MintForm input[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.MintForm form div{
  display: inline-block;
  margin-right: 20px;
  color: var(--white);
}
.MintForm form div.SubmitButton{
  display: block;
}
.MintForm div.SubmitButton button{
  padding: 10px 50px;
  border: 2px solid var(--white);
  background: var(--white);
  margin-top: 10px;
  border-radius: 10px;
  transition: 0.3s;
}
.MintForm div.SubmitButton button:hover{
  background: var(--black);
  color: var(--white);
}


.LimitReached{
  margin: 20px 0;
  font-family:'LEMONMILK_REGULAR';
}

.MyTokens{
  font-family: 'AVENIR';
  font-size: 14px;
  margin-top: 20px;
}
.MyTokens a{
  text-decoration: none;
  font-size: 14px;
  color: #f87da9;
}
.MyTokens a:hover{
  text-decoration: underline;
}
.NotWhitelisted{
  font-size: 14px;
  font-family: 'LEMONMILK_REGULAR';
  color: #f87da9;
}
.PS{
  font-size: 12px;
}
.CheckOpensea{
  font-size: 14px;
}
.CheckOpensea a{
  color: #f87da9;
}

.AllPodz .Container{
  width: 75%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ImageContainer{
  margin: 30px 20px;
}
.ImageContainer img{
  height: 150px;
  width: 150px;
  border: 2px solid var(--white);
}
.ImageContent{
  color: var(--white);
  font-size: 18px;
  font-family: 'LEMONMILK_REGULAR';
}
.ImageContent a{
  color: var(--white);
  text-decoration: none;
  display: block;
  font-size: 12px;
  font-family: 'AVENIR';
}

.LoadButton{
  text-align: center;
  margin: 15px 0;
}
.LoadButton button{
  background: var(--white);
  color: var(--black);
  font-size: 14px;
  outline: none;
  border: 0;
  font-family: 'AVENIR';
  padding: 10px 20px;
  cursor: pointer;
}
.TextContainer{
  margin-top: 30px;
  color: var(--white);
  font-family: 'LEMONMILK_REGULAR';
  text-align: center;
}

@media (max-width: 800px){
  .AllPodz .Container{
    width: 90%;
  } 
}
@media (max-width: 700px){
  .ImageContainer{
    margin: 0 5px;
    margin-top: 20px;
  }
  .ImageContainer img{
    height: 120px;
    width: 120px;
  }
  .ImageContent div{
    font-size: 14px;
  }
}

@media (max-width: 450px){
  .ImageContainer img{
    height: 100px;
    width: 100px;
  }
  .AllPodz .Container{
    width: 100%;
  }
}

@media (max-width: 370px){
  .ImageContainer img{
    height: 80px;
    width: 80px;
  }
}
