:root{
	--black: #000;
	--white: #FFF;
	--gray: #C2C2C2;
	--max-width: 1300px;
}

.Header{
	background: var(--black);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
}
.HeaderContainer{
	max-width: var(--max-width);
	margin: auto;
}
.Container{
	display: flex;
	justify-content: space-between;
	width: 75%;
	margin: auto;
	align-items: center;
	height: 80px;
}
.Logo img{
	height: 50px;
}
.Menu{
	display: flex;
	color: var(--white);
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
}
.Menu.Mobile{
	display: block;
	text-align: center;
	margin-top: 30px;
}
.Menu.Mobile .MenuItem{
	padding: 10px 0;
	font-size: 20px;
}
.MenuItem{
	margin: 0 30px;
	cursor: pointer;
	font-weight: bold;
	transition: 0.3s;
}
.MenuItem:hover{
	color: var(--gray);
}
.Menu a{
	color: var(--white);
	text-decoration: none;
}
.Menu a.Active .MenuItem{
	color: var(--gray);
}

.Hamburger {
  display: inline-block;
  cursor: pointer;
}

.Bar1, .Bar2, .Bar3 {
  width: 35px;
  height: 5px;
  background-color: var(--white);
  margin: 6px 0;
  transition: 0.4s;
}

.Change .Bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.Change .Bar2 {opacity: 0;}

.Change .Bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
.DiscordIcon{
	color: var(--white);
	text-decoration: none;
}
.DiscordIcon img{
	filter: invert(1);
	height: 30px;
	vertical-align: middle;
}
@media (max-width: 950px){
	.Container{
		width: 95%;
	}	
}
@media (max-width: 900px){
	.MenuItem{
		margin: 0 15px;
	}

}
@media (max-width: 768px){
	.Container{
		height: auto;
		padding: 12px 0;
	}
}
@media (max-width: 550px){
	.MenuItem{
		margin: 0 5px;
	}
}


.LockOn {
    display: block;
    visibility: visible;
    position: fixed;
    z-index: 999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color:var(--black);
    text-align: center;
}
.LockOn img{
	width: 120px;
	position: absolute;
	top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}