.MintingPage{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--black);
  z-index: 9999;
  color: var(--white);
}
.Container{
  max-width: var(--max-width);
  margin: auto;
}
.MintingPageContent{
  width: 75%;
  margin: auto;
  position: relative;
  margin-top: 80px;
}
.Close{
  position: absolute;
  right: 0px;
  top: -20px;
  font-size: 48px;
  cursor: pointer;
}
.Title{
  font-family: 'LEMONMILK_REGULAR';
  font-size: 30px;
  font-weight: bold;
}
.Content{
  display: flex;
  justify-content: space-between;
}
.ContentContainer{
  width: 70%;
  margin: auto;
}
.ImageContainer img{
  height: 250px;
}
.Content .Text div{
  margin-top: 20px;
}
.Content .Text{
  font-size: 14px;
  flex-basis: 40%;
}