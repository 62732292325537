.SectionFour{
    max-width: var(--max-width);
    margin: auto;
}
.Container{
    color:var(--white);
    /*border: 2px solid red;*/
    width:75%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.TextContainer{
   /* border: 2px solid orange;*/
    width:500px;
    text-align: left;
}
.ImageContainer img{
    height:auto;
    width:300px;
    border: 2px solid var(--white);
}
.TextOne p:nth-child(1){
    font-size: 40px;
    font-family: 'LEMONMILK_REGULAR';
    margin-bottom: 15px;
}
.TextOne p:nth-child(2){
    margin-bottom: 15px;
}
.TextOne p:nth-child(3){
    margin-bottom: 35px;
}
.TextTwo{
    margin-top: 15px;
}
.One p{
    font-weight: 600;
    font-size: 18px;
}
.ListItem{
    margin-bottom: 15px;
}
.ListItem span{
    font-family: 'LEMONMILK_REGULAR';
    font-size: 18px;
    
}
.EditionsSection{
    display: flex;
    justify-content: space-between;
}
.EditionsSection div:nth-child(1){
    animation: FadeIn 1s forwards;
    opacity: 0;
}
.EditionsSection div:nth-child(2){
    animation: FadeIn 1s forwards;
    -webkit-animation-delay: 1s;
    opacity: 0;
}
.EditionsSection div:nth-child(3){
    animation: FadeIn 1s forwards;
    -webkit-animation-delay: 2s;
    opacity: 0;
}

@keyframes FadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 100;
    }
}

@media(max-width:1200px){
    .Container{
        /* border: 2px solid red;*/
        width:70%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
       justify-content: center;
    }
    .TextContainer{
        /*border: 2px solid orange;*/
        width:500px;
    }
    .ImageContainer img{
        height:auto;
        width:300px;
        border: 2px solid var(--white);
    }
}
@media(max-width:800px){
    .Container{
        /* border: 2px solid red;*/
        width:75%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
       align-items: center;
    }
    .TextContainer{
        /*border: 2px solid orange;*/
        width:500px;
        
    }
    .ImageContainer img{
        height:auto;
        width:300px;
        border: 2px solid var(--white);
    }
}
@media(max-width:768px){
    .TextOne p:nth-child(1){
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .TextContainer{
        /*border: 2px solid orange;*/
        width: 100%;
    }
    .ImageContainer{
        margin-top: 30px;
    }
}
@media(max-width:600px){
    .Container{
    }
    .ImageContainer img{
        height:auto;
        width:300px;
        border: 2px solid var(--white);
    }
}
@media(max-width:550px){
    .Container{
    }
    .ImageContainer img{
        height:auto;
        width:300px;
        border: 2px solid var(--white);
    }

}
@media(max-width: 500px){
    .TextContainer{
        width: 95%;
    }
    .Container{
        width: 95%;
    }
}
@media(max-width:400px){
    .Container{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    }
    .ImageContainer img{
        height:auto;
        width:200px;
        border: 2px solid var(--white);
    }
}