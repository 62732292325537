.SectionNine{
    max-width: var(--max-width);
    margin: auto;
}
.Container{
    color:var(--white);
    width:75%;
    margin:40px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    font-size: 16px;
}
.TextContainer{
    text-align: center;
}
.TextContainer p:nth-child(2){
    word-break: break-all;
}
.TextContainer p a{
    color: var(--white);
}
.ImageContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-basis: 33.33%;
    margin-bottom: -10px;
}
.TwitterContainer, .InstagramContainer{
    align-items: center;
    text-align: center;
}
.TwitterContainer{
    margin-right: 5px;
}

.TwitterContainer img, .InstagramContainer img, .DiscordContainer img{
    padding-top: 5px;
    filter: invert(1);
    padding: 10px;
    height: 20px;
    vertical-align: bottom;
}
.DiscordContainer img{
    height: 28px;
}
.EmailCollection{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.EmailCollection form{
    flex-basis: 33.33%;
}
.EmailCollection p{
    flex-basis: 33.33%;
    text-align: right;
}
.EmailCollection form label{
    display: block;
    font-size: 16px;
}
.EmailCollection form input{
    padding: 4px;
    width: 200px;
    outline: none;
    border: 0;
}
.EmailCollection form input::placeholder{
    font-family: 'AVENIR';
    font-size: 11px;
}
.EmailCollection form button{
    padding: 4px 8px;
    outline: none;
    border: 0;
    background: var(--white);
}
.EmailCollection form button img{
    height: 13px;
    vertical-align: middle;
}
.EmailContainer{
    display: flex;
}
@media(max-width: 768px){
    .Container{
        width:90%;
    }
}
@media (max-width: 950px){
    .EmailCollection{
        display: block;
    }
    .EmailCollection form{
        flex-basis: auto;
        text-align: center;
        margin: 20px 0;
    }
    .EmailCollection p{
        flex-basis: auto;
        text-align: center;
    }
    .ImageContainer{
        flex-basis: auto;
        margin-bottom: 0px;
    }
    .EmailContainer{
        justify-content: center;
    }
}